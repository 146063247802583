@use 'variables';

.ng-dropdown-panel {
  background: var(--bs-white);
  border: none;
  margin-top: 0.5rem !important;
  border-radius: 12px;
  text-align: center;

  .ng-dropdown-panel-items {
    border-radius: 0.75rem;
    box-shadow: #{variables.$form-select-dropdown-shadow};

    .form-check {
      display: flex;
      align-items: flex-end;

      .form-check-label {
        margin-left: #{variables.$form-check-label-margin-left};
        line-height: #{variables.$form-check-label-line-height};
        font-family: var(--bs-font-secondary);
      }

      .form-check-input {
        width: #{variables.$form-select-multi-check-width};
        height: #{variables.$form-select-multi-check-width};
      }
    }
  }

  .ng-option {
    --ds-ng-option-padding-x: #{variables.$form-select-option-padding-x};
    --ds-ng-option-padding-y: #{variables.$form-select-option-padding-y};

    padding: var(--ds-ng-option-padding-y) var(--ds-ng-option-padding-x) !important;
    color: var(--bs-super-dark-gray);
    white-space: break-spaces !important;
    font-family: var(--bs-font-secondary);

    &-label {
      font-weight: 400 !important;
    }

    &-marked {
      background-color: var(--bs-select-mark-color) !important;
      font-weight: 400 !important;
    }

    &-disabled {
      color: var(--bs-gray);
    }
  }

  & > div {
    &::-webkit-scrollbar {
      width: calc(#{variables.$scrollbar-margin} * 2 + #{variables.$scrollbar-width});
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--bs-gray);
      background-clip: padding-box;
      border-radius: #{variables.$scrollbar-border-radius};
      border: #{variables.$scrollbar-margin} solid rgba(0, 0, 0, 0);
    }
  }
}

ds-button {
  &.clear-btn {
    display: none;
    position: absolute;
    right: 26px;
    z-index: 1001;
    padding: 0;
    margin-top: 4px;
    border: 0;
    filter: brightness(1.4);
    scale: 0.9;

    &.ds-select-button--sm {
      margin-top: -6px;
    }
  }

  &:not(.ds-button--with-addon) {
    right: -1rem;
  }
}

.ng-select {
  flex-shrink: 0;
  flex-grow: 1;

  .ng-arrow-wrapper .ng-arrow {
    border: solid var(--bs-super-dark-gray);
    border-width: 0 2px 2px 0;
    padding: 2px;
    top: -5px;

    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  &.ng-select-opened {
    .ng-select-container {
      color: var(--bs-input-focus-color);
      background-color: var(--bs-input-focus-bg);
      border: #{variables.$input-border-width} solid var(--bs-input-focus-border-color) !important;
      outline: 0;

      .ng-arrow-wrapper .ng-arrow {
        border-color: var(--bs-input-focus-border-color);
      }
    }
  }

  &.ng-select-multiple {
    .ng-select-container {
      .ng-value-container {
        padding: 0;

        .ng-input {
          padding: 0;
        }

        .ng-placeholder {
          padding: 0;
          top: auto;
        }
      }
    }
  }

  &.ng-select-clearable {
    &:not(.ds-select--with-addon) {
      .ng-has-value {
        .ng-arrow-wrapper {
          margin-right: 1.5rem;
        }
      }
    }
  }

  .ng-select-container {
    --ds-ng-select-container-border-radius: #{variables.$input-border-radius};
    --ds-ng-select-container-height: #{variables.$input-height};
    --ds-ng-select-container-padding-x: #{variables.$input-padding-x};
    --ds-ng-select-container-padding-y: #{variables.$input-padding-y};

    display: flex;
    align-items: center;
    min-height: var(--ds-ng-select-container-height);
    height: var(--ds-ng-select-container-height);
    padding: var(--ds-ng-select-container-padding-y) var(--ds-ng-select-container-padding-x);
    color: var(--bs-input-color);
    background-color: var(--bs-input-bg);
    border: #{variables.$input-border-width} solid var(--bs-input-border-color);
    border-radius: var(--ds-ng-select-container-border-radius);
    font-family: var(--bs-font-secondary);

    &.ng-has-value {
      .ng-placeholder {
        display: none;
      }
    }

    .ng-value-container {
      display: flex;
      align-items: center;
      padding: 0;
    }

    .ng-clear-wrapper {
      display: none;
    }
  }

  &-disabled {
    --bs-input-bg: var(--bs-light-gray);
    --bs-input-border-color: var(--bs-light-gray);
  }

  &.ds-select {
    &-sm {
      .ng-select-container {
        --ds-ng-select-container-border-radius: #{variables.$input-border-radius-sm};
        --ds-ng-select-container-height: #{variables.$input-height-sm};
        --ds-ng-select-container-padding-x: #{variables.$input-padding-x-sm};
        --ds-ng-select-container-padding-y: #{variables.$input-padding-y-sm};

        @include font-size(#{variables.$input-font-size-sm});
      }

      .ng-dropdown-panel {
        .ng-option {
          --ds-ng-option-padding-x: #{variables.$form-select-option-padding-x-sm};
          --ds-ng-option-padding-y: #{variables.$form-select-option-padding-y-sm};
        }
      }
    }

    &-lg {
      .ng-select-container {
        --ds-ng-select-container-border-radius: #{variables.$input-border-radius-lg};
        --ds-ng-select-container-height: #{variables.$input-height-lg};
        --ds-ng-select-container-padding-x: #{variables.$input-padding-x-lg};
        --ds-ng-select-container-padding-y: #{variables.$input-padding-y-lg};

        @include font-size(#{variables.$input-font-size}-lg);
      }

      .ng-dropdown-panel {
        .ng-option {
          --ds-ng-option-padding-x: #{variables.$form-select-option-padding-x-lg};
          --ds-ng-option-padding-y: #{variables.$form-select-option-padding-y-lg};
        }
      }
    }

    &--with-addon {
      .ng-select-container {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important;
      }
    }
    &--with-description {
      --ds-select-option-label-font-weight: 450;
    }
  }
}

.ng-optgroup,
.ng-option {
  display: flex !important;
  text-align: left;
  align-items: center !important;
}

ds-select {
  .ng-optgroup,
  .ng-option {
    flex-wrap: wrap;

    > * {
      flex-basis: 100%;
    }

    &--label {
      font-weight: var(--ds-select-option-label-font-weight, 400);
    }

    &--description {
      color: var(--bs-gray);
      font-size: 0.8rem;
    }
  }
}

.ng-optgroup {
  color: var(--bs-gray);
  padding: 0.5rem;
  font-size: 0.75rem;
}

.ng-select.ng-select-clearable + .clear-btn {
  display: block;
}

.ds-select .ng-placeholder {
  color: var(--bs-gray);
  font-weight: 400;
  @include text-truncate();
}
