@import "colors";
@import "variables";

:root {
  //
  // Colors
  //
  --#{$prefix}inputBackground: #{$inputBackground};
  --#{$prefix}input-placeholder-color: #{$input-placeholder-color};
  --#{$prefix}white: #{$white};
  --#{$prefix}black: #{$black};
  --#{$prefix}gray: #{$gray};
  --#{$prefix}light-gray: #{$light-gray};
  --#{$prefix}medium-gray: #{$medium-gray};
  --#{$prefix}medium-gray40: #{$medium-gray40};
  --#{$prefix}dark-gray: #{$dark-gray};
  --#{$prefix}gray-other: #{$gray-other};
  --#{$prefix}super-dark-gray: #{$super-dark-gray};
  --#{$prefix}super-light-gray: #{$super-light-gray};
  --#{$prefix}blue: #{$blue};
  --#{$prefix}blue80: #{$blue80};
  --#{$prefix}blue50: #{$blue50};
  --#{$prefix}blue20: #{$blue20};
  --#{$prefix}light-blue: #{$light-blue};
  --#{$prefix}light-blue40: #{$light-blue40};
  --#{$prefix}dark-blue: #{$dark-blue};
  --#{$prefix}border-light-blue: #{$border-light-blue};
  --#{$prefix}blue-bg: #{$blue-bg};
  --#{$prefix}blue-hover: #{$blue-hover};
  --#{$prefix}yellow: #{$yellow};
  --#{$prefix}yellow80: #{$yellow80};
  --#{$prefix}yellow50: #{$yellow50};
  --#{$prefix}yellow20: #{$yellow20};
  --#{$prefix}light-yellow: #{$light-yellow};
  --#{$prefix}dark-yellow: #{$dark-yellow};
  --#{$prefix}yellow-bg: #{$yellow-bg};
  --#{$prefix}helpColor: #{$helpColor};
  --#{$prefix}orange: #{$orange};
  --#{$prefix}orange80: #{$orange80};
  --#{$prefix}orange50: #{$orange50};
  --#{$prefix}orange20: #{$orange20};
  --#{$prefix}dark-orange: #{$dark-orange};
  --#{$prefix}red: #{$red};
  --#{$prefix}red80: #{$red80};
  --#{$prefix}red50: #{$red50};
  --#{$prefix}red20: #{$red20};
  --#{$prefix}light-red: #{$light-red};
  --#{$prefix}dark-red-other: #{$dark-red-other};
  --#{$prefix}form-check-input-border: #{$form-check-input-border};
  --#{$prefix}violet: #{$violet};
  --#{$prefix}violet80: #{$violet80};
  --#{$prefix}violet50: #{$violet50};
  --#{$prefix}violet20: #{$violet20};
  --#{$prefix}light-violet: #{$light-violet};
  --#{$prefix}dark-violet: #{$dark-violet};
  --#{$prefix}dark-violet-other: #{$dark-violet-other};
  --#{$prefix}mint: #{$mint};
  --#{$prefix}mint80: #{$mint80};
  --#{$prefix}mint50: #{$mint50};
  --#{$prefix}mint20: #{$mint20};
  --#{$prefix}dark-mint: #{$dark-mint};
  --#{$prefix}green: #{$green};
  --#{$prefix}green80: #{$green80};
  --#{$prefix}green50: #{$green50};
  --#{$prefix}green20: #{$green20};
  --#{$prefix}light-green: #{$light-green};
  --#{$prefix}dark-green: #{$dark-green};
  --#{$prefix}green-bg: #{$green-bg};
  --#{$prefix}select-mark-color: #{$select-mark-color};
  --#{$prefix}status-red: #{$status-red};
  --#{$prefix}red-bg: #{$red-bg};
  --#{$prefix}status-active: #{$status-active};
  --#{$prefix}status-active-bg: #{$status-active-bg};
  --#{$prefix}status-active-border: #{$status-active-border};
  --#{$prefix}status-inactive: #{$status-inactive};
  --#{$prefix}status-inactive-bg: #{$status-inactive-bg};
  --#{$prefix}status-warning: #{$status-warning};
  --#{$prefix}status-warning-bg: #{$status-warning-bg};
  --#{$prefix}status-warning-border: #{$status-warning-border};
  --#{$prefix}status-error: #{$status-error};
  --#{$prefix}status-error-bg: #{$status-error-bg};
  --#{$prefix}status-error-border: #{$status-error-border};
  --#{$prefix}status-info: #{$status-info};
  --#{$prefix}status-info-bg: #{$status-info-bg};
  --#{$prefix}status-secondary: #{$status-secondary};
  --#{$prefix}status-secondary-bg: #{$status-secondary-bg};

  //
  // Themes
  //
  --ds-theme-primary: var(--#{$prefix}blue);
  --ds-theme-primary-bg-subtle: var(--#{$prefix}blue-bg);
  --ds-theme-primary-bg-hover: var(--#{$prefix}blue-hover);
  --ds-theme-primary-border-subtle: var(--#{$prefix}light-blue);
  --ds-theme-primary-text: var(--#{$prefix}white);

  --ds-theme-success: var(--#{$prefix}green);
  --ds-theme-success-bg-subtle: var(--#{$prefix}status-active-bg);
  --ds-theme-success-border-subtle: var(--#{$prefix}status-active-border);
  --ds-theme-success-text: var(--#{$prefix}white);

  --ds-theme-warning: var(--#{$prefix}status-warning);
  --ds-theme-warning-bg-subtle: var(--#{$prefix}status-warning-bg);
  --ds-theme-warning-border-subtle: var(--#{$prefix}status-warning-border);
  --ds-theme-warning-text: var(--#{$prefix}super-dark-gray);

  --ds-theme-danger: var(--#{$prefix}status-error);
  --ds-theme-danger-bg-subtle: var(--#{$prefix}status-error-bg);
  --ds-theme-danger-border-subtle: var(--#{$prefix}status-error-border);
  --ds-theme-danger-text: var(--#{$prefix}status-error);

  --ds-theme-secondary: var(--#{$prefix}gray);

  //
  // Fonts
  //
  --#{$prefix}font-primary: #{$font-primary};
  --#{$prefix}font-secondary: #{$font-secondary};
  --#{$prefix}font-family-base: #{$font-family-base};
  --#{$prefix}font-awesome: #{$font-awesome};
  --#{$prefix}font-icomoon: #{$font-icomoon};

  //
  // Custom
  //
  --ds-navbar-offset: #{$sidebar-width};
}

// Other
:root {
  --#{$prefix}full-screen-navbar-height: #{$full-screen-navbar-height};

  --#{$prefix}input-border-color: #{$input-border-color};
  --#{$prefix}input-bg: #{$input-bg};
  --#{$prefix}input-color: #{$input-color};
  --#{$prefix}input-focus-bg: #{$input-focus-bg};
  --#{$prefix}input-focus-color: #{$input-focus-color};
  --#{$prefix}input-focus-border-color: #{$input-focus-border-color};
  --#{$prefix}input-group-addon-bg: #{$input-group-addon-bg};
  --#{$prefix}input-group-addon-border-color: #{$input-group-addon-border-color};

  --#{$prefix}form-check-input-checked-border-color: #{$form-check-input-checked-border-color};
  --#{$prefix}form-check-input-checked-bg-color: #{$form-check-input-checked-bg-color};
  --#{$prefix}form-check-input-focus-box-shadow: #{$form-check-input-focus-box-shadow};

  --#{$prefix}form-check-label-color: #{$form-check-label-color};

  --#{$prefix}form-check-radio-border-color: #{$form-check-radio-border-color};
  --#{$prefix}form-check-radio-color: #{$form-check-radio-color};
  --#{$prefix}form-check-radio-bg-image: #{escape-svg($form-check-radio-bg-image)};
  --#{$prefix}form-check-radio-checked-bg-color: #{$form-check-radio-checked-bg-color};
  --#{$prefix}form-check-radio-checked-border-color: #{$form-check-radio-checked-border-color};
  --#{$prefix}form-check-radio-checked-bg-image: #{escape-svg($form-check-radio-checked-bg-image)};

  --#{$prefix}font-size-base: #{$font-size-base};
  --#{$prefix}h1-font-size: #{$h1-font-size};
  --#{$prefix}h2-font-size: #{$h2-font-size};
  --#{$prefix}h3-font-size: #{$h3-font-size};
  --#{$prefix}h4-font-size: #{$h4-font-size};
  --#{$prefix}headings-font-family: #{$headings-font-family};
  --#{$prefix}headings-font-weight: #{$headings-font-weight};
  --#{$prefix}link-color: var(--ds-theme-primary);
  --#{$prefix}link-hover-color: var(--ds-theme-primary-bg-subtle);
  --#{$prefix}link-hover-decoration: #{$link-hover-decoration};

  --#{$prefix}form-label-asterisk-color: #{$form-label-asterisk-color};
}
