@use 'variables';

$states:
  'primary' var(--ds-theme-primary) var(--ds-theme-primary) var(--ds-theme-primary-text) var(--ds-theme-primary-bg-hover)
    var(--ds-theme-primary-bg-hover),
  'primary-outline' inherit var(--ds-theme-primary) var(--ds-theme-primary) inherit var(--ds-theme-primary),
  'info' var(--ds-theme-primary-bg-subtle) var(--ds-theme-primary-bg-subtle) var(--ds-theme-primary) var(--ds-theme-primary-bg-subtle)
    var(--ds-theme-primary-bg-subtle),
  'warning' var(--ds-theme-warning) var(--ds-theme-warning) var(--ds-theme-warning-text) var(--ds-theme-warning) var(--ds-theme-warning),
  'warning-outline' var(--ds-theme-warning-bg-subtle) var(--ds-theme-warning) var(--ds-theme-warning-text) var(--ds-theme-warning-bg-subtle)
    var(--ds-theme-warning),
  'danger' var(--ds-theme-danger-bg-subtle) var(--ds-theme-danger-bg-subtle) var(--ds-theme-danger-text) var(--ds-theme-danger-bg-subtle)
    var(--ds-theme-danger-bg-subtle),
  'danger-outline' inherit var(--ds-theme-danger-border-subtle) var(--ds-theme-danger) inherit var(--ds-theme-danger-border-subtle),
  'link' inherit inherit var(--ds-theme-primary) inherit inherit,
  // Custom
  'success' var(--ds-theme-success) var(--ds-theme-success) var(--ds-theme-success-text) var(--ds-theme-success) var(--ds-theme-success),
  'success-outline' inherit var(--ds-theme-success-border-subtle) var(--ds-theme-success) inherit var(--ds-theme-success-border-subtle),
  'help' var(--ds-button-help-background-color) var(--ds-button-help-background-color) var(--ds-button-help-color)
    var(--ds-button-help-background-color) var(--ds-button-help-background-color);

$hover-opacity-states: 'primary-outline', 'info', 'warning', 'warning-outline', 'danger', 'danger-outline', 'success', 'success-outline', 'help',
  'link';

.ds-button {
  --ds-button-bg: inherit;
  --ds-button-bg-hover: inherit;
  --ds-button-border: inherit;
  --ds-button-border-hover: inherit;
  --ds-button-color: var(--bs-black);
  --ds-button-hover-opacity: 1;

  --ds-button-border-radius: #{variables.$button-medium-border-radius};
  --ds-button-font-size: #{variables.$button-medium-font-size};
  --ds-button-font-weight: #{variables.$button-medium-font-weight};
  --ds-button-height: #{variables.$button-medium-height};
  --ds-button-line-height: #{variables.$button-medium-line-height};
  --ds-button-padding: #{variables.$button-medium-padding};

  --ds-button-help-color: #{variables.$button-help-color};
  --ds-button-help-background-color: #{variables.$button-help-background-color};
  --ds-button-help-hover-color: #{variables.$button-help-hover-color};
  --ds-button-help-hover-background-color: #{variables.$button-help-hover-background-color};

  color: var(--ds-button-color);
  cursor: pointer;
  background-color: var(--ds-button-bg);
  border: 1px solid var(--ds-button-border);
  border-radius: var(--ds-button-border-radius);
  display: inline-flex;
  align-items: center;
  font-family: var(--bs-font-secondary);
  font-size: var(--ds-button-font-size);
  font-weight: var(--ds-button-font-weight);
  height: var(--ds-button-height);
  line-height: var(--ds-button-line-height);
  padding: var(--ds-button-padding);
  text-decoration: none;

  ds-icon {
    span {
      color: var(--ds-button-color) !important;
    }
  }

  &:hover {
    --ds-button-bg: var(--ds-button-bg-hover);
    --ds-button-border: var(--ds-button-border-hover);
    opacity: var(--ds-button-hover-opacity);
  }

  @each $state in $hover-opacity-states {
    &--#{$state} {
      --ds-button-hover-opacity: 0.7;
    }
  }

  @each $state, $bg, $border, $color, $bg-hover, $border-hover in $states {
    &--#{$state} {
      --ds-button-bg: #{$bg};
      --ds-button-border: #{$border};
      --ds-button-color: #{$color};
      --ds-button-bg-hover: #{$bg-hover};
      --ds-button-border-hover: #{$border-hover};
    }
  }

  &--link {
    --ds-button-font-weight: #{variables.$button-link-font-weight} !important;

    &:active {
      text-decoration: underline;
    }
  }

  &--sm {
    --ds-button-border-radius: #{variables.$button-small-border-radius};
    --ds-button-font-size: #{variables.$button-small-font-size};
    --ds-button-font-weight: #{variables.$button-small-font-weight};
    --ds-button-height: #{variables.$button-small-height};
    --ds-button-line-height: #{variables.$button-small-line-height};
    --ds-button-padding: #{variables.$button-small-padding};
  }

  &--lg {
    --ds-button-border-radius: #{variables.$button-big-border-radius};
    --ds-button-font-size: #{variables.$button-big-font-size};
    --ds-button-font-weight: #{variables.$button-big-font-weight};
    --ds-button-height: #{variables.$button-big-height};
    --ds-button-line-height: #{variables.$button-big-line-height};
    --ds-button-padding: #{variables.$button-big-padding};
  }

  &.ds-button-icon {
    &.ds-button--sm {
      --ds-button-padding: #{variables.$button-icon-small-padding};
    }

    &.ds-button--md {
      --ds-button-padding: #{variables.$button-icon-medium-padding};
    }

    &.ds-button--lg {
      --ds-button-padding: #{variables.$button-icon-big-padding};
    }

    .ds-img {
      &--before {
        margin-right: 1rem;
      }

      &--after {
        margin-left: 1rem;
      }
    }
  }

  &.ds-no-padding {
    padding: 0;
  }
}
