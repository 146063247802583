body {
  height: 100vh;
  margin: 0;
  padding: 0;

  h1 {
    font-weight: var(--bs-headings-font-weight);
    line-height: 120%;
    letter-spacing: -0.88px;
    color: var(--bs-super-dark-gray, #4B4446);
  }

  h2 {
    font-weight: var(--bs-headings-font-weight);
    line-height: 120%;
    color: var(--bs-super-dark-gray, #4B4446);
  }

  .ds-button--lg  {
    --ds-button-font-weight: var(--bs-headings-font-weight);
  }

  .no-padding-button {
    button, a {
      padding: 0 !important;
    }
  }

  .stepper-list--item {
    .STARTED {
      ds-img {
        animation: rotation 2s infinite linear;
      }
    }
  }


  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
}
