.ds-datepicker {
  border: none !important;
  font-family: var(--bs-font-primary);

  ngb-datepicker-navigation {
    margin-bottom: 12px;

    .form-select {
      border: none;
      outline: none;
      box-shadow: unset !important;
      cursor: pointer;
      background: none;
    }

    .ngb-dp-arrow-btn {
      color: var(--bs-blue);
    }
  }

  .ngb-dp-day,
  .ngb-dp-weekday,
  .ngb-dp-week-number {
    width: 45px;
    height: 45px;
  }

  .ngb-dp-day {
    &[tabindex='0'] {
      background: var(--bs-blue);
      color: var(--bs-white);
      border-radius: 6px;

      .custom-day {
        &:hover {
          color: var(--bs-white);
        }
      }
    }
  }

  .ngb-dp-weekdays {
    border-bottom: none;
  }

  .ngb-dp-weekday {
    font-size: 12px;
    font-style: normal;
  }

  .disabled {
    // TODO
    color: #bbb;
  }

  .custom-day {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 14px;
    border-radius: 6px;

    &.faded,
    &:hover {
      // TODO
      background-color: rgba(var(--bs-blue), 0.05);
      color: var(--bs-blue);
    }

    &.range {
      &:not(.faded) {
        background-color: var(--bs-blue);
        border-radius: 50%;
        color: var(--bs-white);
        height: 100%;
      }
    }

    &.weekend {
      // TODO
      background-color: #f5f6f9;
    }
  }

  .ngb-dp-weekday {
    // TODO
    color: #aaaaaa;
  }

  &.dropdown-menu {
    border-radius: 1.25rem;
    padding: 2rem;
    // TODO
    box-shadow: 0 2px 10px -15px rgba(0, 0, 0, 0.05),
    0px 10px 40px -20px rgba(0, 0, 0, 0.15);
  }
}


.date-range-dropdown {
  &.dropdown-menu {
    padding: 16px;
  }

  ngb-datepicker {
    border: none !important;
    font-family: var(--bs-font-primary);

    ngb-datepicker-navigation {
      margin-bottom: 12px;

      .form-select {
        border: none;
        outline: none;
        box-shadow: none !important;
        cursor: pointer;
        background: none;
      }

      .ngb-dp-arrow-btn {
        color: var(--bs-blue);
      }
    }

    .ngb-dp-day,
    .ngb-dp-weekday,
    .ngb-dp-week-number {
      width: 2rem;
      height: 2rem;
    }

    .ngb-dp-day {
      &[tabindex='0'] {
        background: var(--bs-blue);
        color: var(--bs-white);
        border-radius: 50%;

        .custom-day {
          &:hover {
            color: var(--bs-white);
          }
        }
      }
    }

    .ngb-dp-weekdays {
      border-bottom: none;
    }

    .ngb-dp-weekday {
      font-size: 12px;
      font-style: normal;
    }

    .disabled {
      color: #bbb;
    }

    .custom-day {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-size: 14px;

      &.faded,
      &:hover {
        background-color: rgba(var(--bs-blue), 0.05);
        color: var(--bs-blue);
      }

      &.range {
        &:not(.faded) {
          background-color: var(--bs-blue);
          border-radius: 50%;
          color: var(--bs-white);
          height: 100%;
        }
      }

      &.weekend {
        background-color: #f5f6f9;
      }
    }

    .ngb-dp-weekday {
      color: #aaaaaa;
    }
  }

  ngb-datepicker.dropdown-menu {
    border-radius: 1.25rem;
    padding: 2rem;
    z-index: 1002;
    box-shadow: 0 2px 10px -15px rgba(0, 0, 0, 0.05), 0px 10px 40px -20px rgba(0, 0, 0, 0.15);
  }
}
