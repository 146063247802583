.search-with-context {
  &.ng-select {
    min-width: 225px;
    max-width: 50%;

    .ng-select-container {
      border-left: none;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      border-color: #f4f5f7 !important;
      box-shadow: none !important;
    }

    .ng-value-container {
      justify-content: flex-end;
      position: relative;
      cursor: pointer;

      .ng-input {
        display: none !important;
      }

      .ng-value {
        color: var(--bs-input-color);
        font-weight: 450;
        font-size: 0.875rem;
        margin-right: 0.625rem;
        display: flex;
        align-items: center;
      }
    }

    &.invalid {
      .ng-select-container {
        background: var(--bs-status-error);
        border-color: var(--bs-status-error-bg) !important;
      }
    }
  }
}
