// UTILS CLASS FOR COMMON USE

.ds-center {
  display: flex;
  justify-content: center;

  & > * {
    width: 100%;
  }
}

.ds-nowrap {
  text-wrap: nowrap;
}
