@font-face {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/geomanist-light.eot');
  src: url('../fonts/geomanist-light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/geomanist-light.woff2') format('woff2'),
  url('../fonts/geomanist-light.woff') format('woff'),
  url('../fonts/geomanist-light.ttf') format('truetype'),
  url('../fonts/geomanist-light.svg#Geomanist-Light') format('svg');
}

@font-face {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/geomanist-regular.eot');
  src: url('../fonts/geomanist-regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/geomanist-regular.woff2') format('woff2'),
  url('../fonts/geomanist-regular.woff') format('woff'),
  url('../fonts/geomanist-regular.ttf') format('truetype'),
  url('../fonts/geomanist-regular.svg#Geomanist-Regular') format('svg');
}

@font-face {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 450;
  src: url('../fonts/geomanist-book.eot');
  src: url('../fonts/geomanist-book.eot?#iefix') format('embedded-opentype'),
  url('../fonts/geomanist-book.woff2') format('woff2'),
  url('../fonts/geomanist-book.woff') format('woff'),
  url('../fonts/geomanist-book.ttf') format('truetype'),
  url('../fonts/geomanist-book.svg#Geomanist-Bold') format('svg');
}

@font-face {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/geomanist-medium.eot');
  src: url('../fonts/geomanist-medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/geomanist-medium.woff2') format('woff2'),
  url('../fonts/geomanist-medium.woff') format('woff'),
  url('../fonts/geomanist-medium.ttf') format('truetype'),
  url('../fonts/geomanist-medium.svg#Geomanist-Medium') format('svg');
}

@font-face {
  font-family: 'icomoon-ds';
  src:  url('../fonts/icomoon-ds.eot?qas2la');
  src:  url('../fonts/icomoon-ds.eot?qas2la#iefix') format('embedded-opentype'),
  url('../fonts/icomoon-ds.ttf?qas2la') format('truetype'),
  url('../fonts/icomoon-ds.woff?qas2la') format('woff'),
  url('../fonts/icomoon-ds.svg?qas2la#icomoon-ds') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
