[class^="ds-icon-"], [class*=" ds-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-ds' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ds-icon-stock:before {
  content: "\a901";
}
.ds-icon-logout:before {
  content: "\a902";
}
.ds-icon-tag:before {
  content: "\a903";
}
.ds-icon-bucket2:before {
  content: "\a904";
}
.ds-icon-next:before {
  content: "\a905";
}
.ds-icon-arrow-left:before {
  content: "\a906";
}
.ds-icon-back:before {
  content: "\a907";
}
.ds-icon-close:before {
  content: "\a908";
}
.ds-icon-dots:before {
  content: "\a909";
}
.ds-icon-download:before {
  content: "\a90a";
}
.ds-icon-identifier:before {
  content: "\a90b";
}
.ds-icon-pencil:before {
  content: "\a90c";
}
.ds-icon-save:before {
  content: "\a90d";
}
.ds-icon-certificate:before {
  content: "\a90e";
}
.ds-icon-certificate-revoked:before {
  content: "\a910";
}
.ds-icon-certificate-valid:before {
  content: "\a911";
}
.ds-icon-copy:before {
  content: "\a912";
}
.ds-icon-card-replacement:before {
  content: "\a913";
}
.ds-icon-up:before {
  content: "\a914";
}
.ds-icon-down:before {
  content: "\a915";
}
.ds-icon-warning .path1:before {
  content: "\a916";
  color: rgb(0, 0, 0);
}
.ds-icon-warning .path2:before {
  content: "\a917";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ds-icon-waiting .path1:before {
  content: "\a918";
  color: rgb(0, 0, 0);
}
.ds-icon-waiting .path2:before {
  content: "\a919";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ds-icon-success .path1:before {
  content: "\a91a";
  color: rgb(0, 0, 0);
}
.ds-icon-success .path2:before {
  content: "\a91b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ds-icon-info .path1:before {
  content: "\a91c";
  color: rgb(0, 0, 0);
}
.ds-icon-info .path2:before {
  content: "\a91d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ds-icon-error .path1:before {
  content: "\a91e";
  color: rgb(0, 0, 0);
}
.ds-icon-error .path2:before {
  content: "\a91f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ds-icon-report:before {
  content: "\a920";
}
.ds-icon-browsers .path1:before {
  content: "\a921";
  color: rgb(255, 255, 255);
}
.ds-icon-browsers .path2:before {
  content: "\a922";
  margin-left: -1.0498046875em;
  color: rgb(255, 255, 255);
}
.ds-icon-browsers .path3:before {
  content: "\a923";
  margin-left: -1.0498046875em;
  color: rgb(255, 255, 255);
}
.ds-icon-browsers .path4:before {
  content: "\a924";
  margin-left: -1.0498046875em;
  color: rgb(0, 0, 0);
}
.ds-icon-card:before {
  content: "\a925";
}
.ds-icon-search:before {
  content: "\a930";
  color: #73696b;
}
.ds-icon-timer:before {
  content: "\a931";
}
.ds-icon-status-change:before {
  content: "\a932";
}
.ds-icon-suitcase .path1:before {
  content: "\a933";
  color: rgb(255, 255, 255);
}
.ds-icon-suitcase .path2:before {
  content: "\a934";
  margin-left: -0.9560546875em;
  color: rgb(0, 0, 0);
}
.ds-icon-building .path1:before {
  content: "\a935";
  color: rgb(255, 255, 255);
}
.ds-icon-building .path2:before {
  content: "\a936";
  margin-left: -1em;
  color: rgb(231, 232, 231);
}
.ds-icon-building .path3:before {
  content: "\a937";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ds-icon-building .path4:before {
  content: "\a938";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.ds-icon-diamond:before {
  content: "\a939";
}
.ds-icon-logo .path1:before {
  content: "\a93a";
  color: rgb(104, 205, 252);
}
.ds-icon-logo .path2:before {
  content: "\a93b";
  margin-left: -1em;
  color: rgb(0, 163, 224);
}
.ds-icon-logo .path3:before {
  content: "\a93c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ds-icon-simplysign:before {
  content: "\a93d";
}
.ds-icon-edelivery:before {
  content: "\a93e";
}
.ds-icon-wallet .path1:before {
  content: "\e900";
  color: rgb(244, 244, 244);
}
.ds-icon-wallet .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: rgb(200, 200, 200);
}
.ds-icon-wallet .path3:before {
  content: "\e902";
  margin-left: -1em;
  color: rgb(115, 105, 107);
}
.ds-icon-wallet .path4:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(115, 105, 107);
}
.ds-icon-agreement .path1:before {
  content: "\e92a";
  color: rgb(200, 200, 200);
}
.ds-icon-agreement .path2:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(115, 105, 107);
}
.ds-icon-shopping-cart .path1:before {
  content: "\e904";
  color: rgb(236, 240, 241);
}
.ds-icon-shopping-cart .path2:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(233, 233, 233);
}
.ds-icon-shopping-cart .path3:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(115, 105, 107);
}
.ds-icon-dashboard .path1:before {
  content: "\e907";
  color: rgb(233, 233, 233);
}
.ds-icon-dashboard .path2:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(200, 200, 200);
}
.ds-icon-dashboard .path3:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(115, 105, 107);
}
.ds-icon-list-lock .path1:before {
  content: "\e90a";
  color: rgb(255, 255, 255);
}
.ds-icon-list-lock .path2:before {
  content: "\e90b";
  margin-left: -1.0419921875em;
  color: rgb(255, 255, 255);
}
.ds-icon-list-lock .path3:before {
  content: "\e90c";
  margin-left: -1.0419921875em;
  color: rgb(115, 105, 107);
}
.ds-icon-list-lock .path4:before {
  content: "\e90d";
  margin-left: -1.0419921875em;
  color: rgb(198, 198, 198);
}
.ds-icon-list-lock .path5:before {
  content: "\e90e";
  margin-left: -1.0419921875em;
  color: rgb(255, 255, 255);
}
.ds-icon-list-lock .path6:before {
  content: "\e90f";
  margin-left: -1.0419921875em;
  color: rgb(236, 250, 255);
}
.ds-icon-list-lock .path7:before {
  content: "\e910";
  margin-left: -1.0419921875em;
  color: rgb(115, 105, 107);
}
.ds-icon-mail-send .path1:before {
  content: "\e911";
  color: rgb(200, 200, 200);
}
.ds-icon-mail-send .path2:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ds-icon-mail-send .path3:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(115, 105, 107);
}
.ds-icon-cog .path1:before {
  content: "\e914";
  color: rgb(200, 200, 200);
}
.ds-icon-cog .path2:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ds-icon-cog .path3:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(115, 105, 107);
}
.ds-icon-cog .path4:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(115, 105, 107);
}
.ds-icon-briefcase .path1:before {
  content: "\e918";
  color: rgb(255, 255, 255);
}
.ds-icon-briefcase .path2:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(200, 200, 200);
}
.ds-icon-briefcase .path3:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(115, 105, 107);
}
.ds-icon-browsers2 .path1:before {
  content: "\e91b";
  color: rgb(255, 255, 255);
}
.ds-icon-browsers2 .path2:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(200, 200, 200);
}
.ds-icon-browsers2 .path3:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ds-icon-browsers2 .path4:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(115, 105, 107);
}
.ds-icon-mail .path1:before {
  content: "\e91f";
  color: rgb(200, 200, 200);
}
.ds-icon-mail .path2:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(244, 244, 244);
}
.ds-icon-mail .path3:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(115, 105, 107);
}
.ds-icon-paperplane .path1:before {
  content: "\e922";
  color: rgb(244, 244, 244);
}
.ds-icon-paperplane .path2:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(200, 200, 200);
}
.ds-icon-paperplane .path3:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(115, 105, 107);
}
.ds-icon-renewal .path1:before {
  content: "\e925";
  color: rgb(200, 200, 200);
}
.ds-icon-renewal .path2:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(115, 105, 107);
}
.ds-icon-renewal .path3:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(200, 200, 200);
}
.ds-icon-renewal .path4:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(115, 105, 107);
}
.ds-icon-renewal .path5:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ds-icon-notifications .path1:before {
  content: "\e92c";
  color: #c8c8c8;
}
.ds-icon-notifications .path2:before {
  content: "\e92d";
  color: #c8c8c8;
  margin-left: -0.9599609375em;
}
.ds-icon-notifications .path3:before {
  content: "\e92e";
  color: #fff;
  margin-left: -0.9599609375em;
}
.ds-icon-notifications .path4:before {
  content: "\e92f";
  color: #73696b;
  margin-left: -0.9599609375em;
}
.ds-icon-notifications .path5:before {
  content: "\e930";
  color: #73696b;
  margin-left: -0.9599609375em;
}
.ds-icon-support .path1:before {
  content: "\e931";
  color: #c8c8c8;
}
.ds-icon-support .path2:before {
  content: "\e932";
  color: #c8c8c8;
  margin-left: -1em;
}
.ds-icon-support .path3:before {
  content: "\e933";
  color: #73696b;
  margin-left: -1em;
}
.ds-icon-certum .path1:before {
  content: "\e934";
  color: #c8c8c8;
}
.ds-icon-certum .path2:before {
  content: "\e935";
  color: #73696b;
  margin-left: -0.869140625em;
}
.ds-icon-certum .path3:before {
  content: "\e936";
  color: #fff;
  margin-left: -0.869140625em;
}
.ds-icon-calendar:before {
  content: "\e937";
}
.ds-icon-check-circle .path1:before {
  content: "\e93b";
  color: #f4f4f4;
}
.ds-icon-check-circle .path2:before {
  content: "\e93c";
  color: #73696b;
  margin-left: -1em;
}
.ds-icon-email2:before {
  content: "\e93a";
}
.ds-icon-user:before {
  content: "\e939";
}
.ds-icon-package:before {
  content: "\e938";
}
.ds-icon-chat:before {
  content: "\e93d";
}
