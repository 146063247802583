// TODO - move to vars

$white: #FFFFFF;
$black: #231F20;
$gray: #A7A5A6;
$background: #F4F4F4;
$light-gray: #E9E9E9;
$medium-gray: #C8C8C8;
$medium-gray40: rgba($medium-gray, .4);
$dark-gray: #73696B;
$super-dark-gray: #4B4446;
$super-light-gray: #f4f4f4;
$gray-other: #A7A5A6;
$blue: #00A3E0;
$blue80: rgba(0, 163, 224, 0.8);
$blue50: rgba(0, 163, 224, 0.5);
$blue20: rgba(0, 163, 224, 0.2);
$light-blue: rgb(104,205,252);
$light-blue40: rgba($light-blue, .4);
$dark-blue: #0269C8;
$border-light-blue: #8EDBFF;
$blue-bg: #ECFAFF;
$blue-hover: #21B9F2;
$yellow: #FDC300;
$yellow80: rgba(253, 195, 0, 0.8);
$yellow50: rgba(253, 195, 0, 0.5);
$yellow20: rgba(253, 195, 0, 0.2);
$light-yellow: #FEF140;
$dark-yellow: #C99B22;
$yellow-bg: #FFF9E6;
$helpColor: #FEE09A;
$orange: #F28700;
$orange80: rgba(242, 135,	0, 0.8);
$orange50: rgba(242, 135,	0, 0.5);
$orange20: rgba(242, 135,	0, 0.2);
$dark-orange: #BF6D1C;
$red: #E84F0F;
$red80: rgba(232, 79, 15, 0.8);
$red50: rgba(232, 79, 15, 0.5);
$red20: rgba(232, 79, 15, 0.2);
$light-red: #FC361D;
$dark-red: #D02515;
$dark-red-other: #B8421D;
$violet: #8581BD;
$violet80: rgba(133, 129, 189, 0.8);
$violet50: rgba(133, 129, 189, 0.5);
$violet20: rgba(133, 129, 189, 0.2);
$light-violet: #9998F4;
$dark-violet: #343797;
$dark-violet-other: #6B6996;
$mint: #5BC199;
$mint80: rgba(91, 193, 153, 0.8);
$mint50: rgba(91, 193, 153, 0.5);
$mint20: rgba(91, 193, 153, 0.2);
$dark-mint: #51957A;
$green: #00A04F;
$green80: rgba(0, 160, 79, 0.8);
$green50: rgba(0, 160, 79, 0.5);
$green20: rgba(0, 160, 79, 0.2);
$light-green: #3BCB6D;
$dark-green: #208544;
$green-bg: #E6FAEE;
$select-mark-color: #ECFAFF;
$status-red: #EF153A;
$red-bg: #FEEEF1;
$status-active: #00C755;
$status-active-bg: $green-bg;
$status-active-border: $status-active;
$status-inactive: $medium-gray;
$status-inactive-bg: $background;
$status-warning: #FFB82F;
$status-warning-bg: $yellow-bg;
$status-warning-border: $status-warning;
$status-error: $status-red;
$status-error-bg: $red-bg;
$status-error-border: rgba($status-error, 0.3);
$status-info: $light-blue;
$status-info-bg: $blue-bg;
$status-secondary: $dark-gray;
$status-secondary-bg: $light-gray;
$inputBackground: $background;
$input-placeholder-color: $gray-other;
