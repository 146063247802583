@import 'mixins';

p {
  font-family: var(--bs-font-secondary);
  @include paragraph();

  &.p--headline {
    @include paragraph(4rem, 110%);
  }

  &.p--lg {
    @include paragraph(1.125rem, 180%);
  }

  &.p--s {
    @include paragraph(0.875rem, 160%, -0.02em);
  }

  &.p--xs {
    @include paragraph(0.75rem, 150%, -0.02em);
  }
}

.form-label {
  font-family: var(--bs-font-primary);
  text-transform: uppercase;
}

body {
  font-family: var(--bs-font-primary);
}

b, strong {
  font-weight: 450;
}
