@mixin blueButtonLink() {
  font-family: $font-primary;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1db0e5;
}

@mixin h1($color: #38404e, $margin-bottom: 2.5rem, $margin-top: 2.875rem) {
  font-family: $font-secondary;
  font-size: 33px;
  font-weight: 300;
  letter-spacing: -0.1px;
  color: $color;
  display: flex;
  margin-bottom: $margin-bottom;
  margin-top: $margin-top;
}

@mixin h3() {
  margin-top: 1.7rem;
  text-align: center;
  font-family: $font-secondary;
  font-size: 1.25rem;
  letter-spacing: 0.1px;
  color: rgba(152, 162, 179, .75);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  margin-bottom: 1rem;
}

@mixin paragraph($font-size: 1rem, $line-height: 160%, $letter-spacing: 1, $color: #4B4446) {
  font-family: $font-secondary;
  font-size: $font-size;
  font-weight: 400;
  letter-spacing:$letter-spacing;
  color: $color;
  line-height: $line-height;
}

@mixin navTabMaxLg() {
  @media (max-width: var(--bs-grid-breakpoints-lg)) {
    position: fixed;
    bottom: 0;
    padding: 0;
    width: 100%;
    box-shadow: 0 -1px 0 0 rgba(108, 108, 108, 0.17);
    justify-content: center !important;
    z-index: 1;

    .nav-link {
      font-family: $font-secondary;
      font-size: .9rem;
      font-weight: 400;
      color: #6d6d6d;

      i, .icon, [class^="icon-"] {
        display: block;
        font-size: 2.8rem;
        text-align: center;
        margin-bottom: .75rem;

        &:before {
          color: #a8b8d0 !important;
        }

      }

      &:hover,
      &.active {
        i, .icon, [class^="icon-"] {
          &:before {
            color: $blue !important;
          }
        }
      }
    }
  }
}

