$tooltip-bg: var(--bs-white);
$tooltip-padding-x: 1.5rem;
$tooltip-padding-y: 1.5rem;
$tooltip-font-size: 0.75rem;
$tooltip-color: var(--bs-dark-gray);
$tooltip-border-radius: 0.5rem;
$tooltip-arrow-color: $tooltip-bg;
$tooltip-arrow-height: 0.5rem;
$tooltip-arrow-width: 0.5rem;
$tooltip-max-width: 300px;
$tooltip-opacity: 1;

.tooltip {
  --#{$prefix}tooltip-bg: #{$tooltip-bg};
  --#{$prefix}tooltip-padding-x: #{$tooltip-padding-x};
  --#{$prefix}tooltip-padding-y: #{$tooltip-padding-y};
  --#{$prefix}tooltip-font-size: #{$tooltip-font-size};
  --#{$prefix}tooltip-color: #{$tooltip-color};
  --#{$prefix}tooltip-border-radius: #{$tooltip-border-radius};
  --#{$prefix}tooltip-arrow-color: #{$tooltip-arrow-color};
  --#{$prefix}tooltip-arrow-height: #{$tooltip-arrow-height};
  --#{$prefix}tooltip-arrow-width: #{$tooltip-arrow-width};
  --#{$prefix}tooltip-max-width: #{$tooltip-max-width};
  --#{$prefix}tooltip-opacity: #{$tooltip-opacity};
  --#{$prefix}tooltip-shadow: 0 20px 100px -40px rgba(0, 0, 0, 0.7), 0px 10px 20px -10px rgba(0, 0, 0, 0.2);

  &--dark {
    --#{$prefix}tooltip-bg: var(--#{$prefix}super-dark-gray);
    --#{$prefix}tooltip-arrow-color: var(--#{$prefix}super-dark-gray);
    --#{$prefix}tooltip-color: var(--#{$prefix}white);
    --#{$prefix}tooltip-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.05), 0px 20px 100px -40px rgba(0, 0, 0, 0.5);
  }

  &--warn {
    --#{$prefix}tooltip-bg: #ffefcf;
    --#{$prefix}tooltip-arrow-color: #ffefcf;
    --#{$prefix}tooltip-shadow: 0px 10px 30px -10px rgba(255, 184, 0, 0.05), 0px 4px 12px 0px rgba(255, 214, 0, 0.05);
  }

  .tooltip-inner {
    box-shadow: var(--#{$prefix}tooltip-shadow);
    font-family: var(--bs-font-secondary);
  }

  .tooltip-arrow,
  .arrow {
    transform: none !important;
    width: 0;
    height: 0;
    left: auto !important;

    &::before {
      border-width: 0;
      transform: rotate(45deg);
      border-radius: 0.188rem;
      height: calc(var(--#{$prefix}tooltip-arrow-height) * 2);
      width: calc(var(--#{$prefix}tooltip-arrow-width) * 2);
      background: var(--#{$prefix}tooltip-arrow-color);
    }
  }

  &.bs-tooltip-start,
  &.bs-tooltip-left {
    margin-right: 0.25rem !important;

    .tooltip-arrow,
    .arrow {
      top: 50% !important;

      &::before {
        top: calc(-1 * var(--#{$prefix}tooltip-arrow-height));
        left: calc(var(--#{$prefix}tooltip-arrow-width) * -2.25);
      }
    }
  }

  &.bs-tooltip-end,
  &.bs-tooltip-right {
    margin-left: 0.25rem !important;

    .tooltip-arrow,
    .arrow {
      top: 50% !important;

      &::before {
        top: calc(-1 * var(--#{$prefix}tooltip-arrow-height));
        left: calc(var(--#{$prefix}tooltip-arrow-width) / -2);
      }
    }
  }

  &.bs-tooltip-top {
    margin-bottom: 0.25rem !important;

    .tooltip-arrow,
    .arrow {
      left: 50% !important;

      &::before {
        top: calc(var(--#{$prefix}tooltip-arrow-height) * -2.25);
        left: calc(-1 * var(--#{$prefix}tooltip-arrow-width));
      }
    }
  }

  &.bs-tooltip-bottom {
    margin-top: 0.25rem !important;

    .tooltip-arrow,
    .arrow {
      left: 50% !important;

      &::before {
        top: calc(var(--#{$prefix}tooltip-arrow-height) / 2);
        left: calc(-1 * var(--#{$prefix}tooltip-arrow-width));
      }
    }
  }

  &.-start,
  &.bs-tooltip-top-left,
  &.bs-tooltip-bottom-left {
    left: -1rem !important;

    .tooltip-arrow,
    .arrow {
      left: 1.5rem !important;
    }
  }

  &.-end,
  &.bs-tooltip-top-right,
  &.bs-tooltip-bottom-right {
    right: -1rem !important;

    .tooltip-arrow,
    .arrow {
      left: calc(100% - 1.5rem) !important;
    }
  }

  &.-top,
  &.bs-tooltip-right-top,
  &.bs-tooltip-left-top {
    top: -1rem !important;

    .tooltip-arrow,
    .arrow {
      top: 1.5rem !important;
    }
  }

  &.-bottom,
  &.bs-tooltip-right-bottom,
  &.bs-tooltip-left-bottom {
    top: 0 !important;

    .tooltip-arrow,
    .arrow {
      top: 1.5rem !important;
    }
  }
}
